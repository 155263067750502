




















































































































.tools-content{
  padding: 10px 0;
  border-bottom: 1px #ccc solid;
  .tools-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    background-color: #fff;

    .input-wrapper {
      position: relative;

      > input {
        width: 400px;
        height: 35px;
        background-color: transparent;
        border: 1px #ccc solid;
        border-radius: 17px;
        box-sizing: border-box;
        outline: none;
        padding-left: 20px;
      }

      > i {
        width: 23px;
        height: 23px;
        position: absolute;
        top: 6px;
        right: 12px;
        display: inline-flex;
        background: url("../assets/img/search-btn.png") no-repeat center;
      }
    }

    .router-wrapper {
      display: flex;
      align-items: center;

      > * {
        margin-right: 5px;
        color: #BBBCBC;
        font-size: 16px;
      }

      > i {
        width: 23px;
        height: 23px;
        display: inline-flex;
        background: url("../assets/img/home.png") no-repeat center;
      }

      > a{
        transition: color 0.2s linear;
        &:hover{
          color: #333;
        }
      }
    }
  }
}
section {
  main {
    display: flex;
    justify-content: center;
    width: 1200px;
    margin: 0 auto;
    padding: 50px 0;

    > div {
      margin: 0 20px;

      &:nth-child(1) {
        width: 800px;

        > h2 {
          font-size: 28px;
          color: #000;
          line-height: 32px;
          font-weight: normal;
          border-bottom: 1px solid #f2f2f2;
          padding-bottom: 30px;
        }

        > div {
          &:nth-child(1) {
            > h2 {
              font-size: 26px;
              color: #333;
              line-height: 34px;
              font-weight: normal;
            }

            > div {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              margin-top: 10px;

              > i {
                width: 22px;
                height: 22px;
                background: url("../assets/img/clock.png") no-repeat center;
              }

              > span {
                height: 22px;
                line-height: 22px;
                font-size: 14px;
                color: #b4b4b4;
                margin-left: 5px;
              }
            }
          }

          &:nth-child(2) {
            padding-top: 20px;
            overflow: hidden;
          }
        }
      }

      &:nth-child(2) {
        width: 400px;
        position: relative;
        height: 100vh;
        overflow: scroll;

        > h2 {
          font-size: 22px;
          line-height: 1.2;
          color: #333;
          padding-bottom: 20px;
          border-bottom: 1px solid #f2f2f2;
          padding-left: 6%;

          &:before {
            width: 6px;
            height: 22px;
            background: #000096;
            content: "";
            position: absolute;
            top: 2px;
            left: 0;
          }
        }

        > ul {
          margin-top: 10px;

          > li {
            padding: 10px 30px;
            position: relative;

            > a {
              font-size: 16px;
              color: #666;
              display: inline-block;
            }

            &:hover {
              > a {
                color: #000096;
              }

              &:before {
                width: 6px;
                height: 6px;
                background: #000096;
                content: "";
                position: absolute;
                top: 19px;
                left: 0;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .tools-content{
    .tools-wrapper {
      width: 100%;
    }
  }
  section {
    main {
      width: 100%;
      >div:nth-child(1){
        width: 100%;
        ::v-deep img{
          width: 100%;
          height: auto;
        }
      }
      >div:nth-child(2){
        display: none;
      }
    }
  }
}























































































header {
  > div:nth-child(1) {
    > div:nth-child(1) {
      position: absolute;
      top: 171px;
      left: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 1;
      color: #fff;

      > div:nth-child(1) {
        font-size: 34px;
        line-height: 1;
      }

      > div:nth-child(2) {
        font-size: 20px;
        line-height: 1.3;
      }
    }

    > img{
      width: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  > div:nth-child(2) {
      height: 53px;
      border-bottom: 1px #ccc solid;
    .tools-wrapper {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1200px;
      margin: 0 auto;
      background-color: #fff;
      padding: 6px 9px 0 15px;

      .input-wrapper {
        position: relative;

        > input {
          width: 400px;
          height: 35px;
          background-color: transparent;
          border: 1px #ccc solid;
          border-radius: 17px;
          box-sizing: border-box;
          outline: none;
          padding-left: 20px;
        }

        > i {
          width: 23px;
          height: 23px;
          position: absolute;
          top: 7px;
          right: 10px;
          display: inline-flex;
          background: url("../assets/img/search-btn.png") no-repeat center;
        }
      }

      .router-wrapper {
        display: flex;
        align-items: center;

        > * {
          margin-right: 5px;
          color: #BBBCBC;
          font-size: 16px;
        }

        > i {
          width: 23px;
          height: 23px;
          display: inline-flex;
          background: url("../assets/img/home.png") no-repeat center;
        }

        > a{
          transition: color 0.2s linear;
          &:hover{
            color: #333;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  header {
    > div:nth-child(1) {
      img {
        height: 60vw;
        // width: 100%;
      }
      > div:nth-child(1){
        > div:nth-child(1){
          font-size: 20px;
          line-height: 24px;
        }
      }

    }
    > div:nth-child(2) {
      >.tools-wrapper {
        width: 100%;
        flex-wrap: wrap;
        >.input-wrapper {
          width: 100%;
          >input{
            width: 100%;
          }
        }
        >.router-wrapper {
          margin-left: auto;
        }
      }
    }
  }
}
